.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 65%;
  .title {
    color: var(--pure-white);
    font-size: 3rem;
    font-weight: 600;
    border-bottom: 2px solid var(--pure-white);
  }
  .text {
    font-size: 1.2rem;
    text-align: justify;
    color: var(--pure-white);
  }
  .teams {
    margin: 4rem 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    .team {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      .name {
        font-size: 1.8rem;
        font-weight: 600;
        display: flex;
        gap: 0.2rem;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: var(--pure-white);
      }
      .position {
        font-size: 1rem;
        /* color: var(--pure-black); */
        color: #c2c2c2;
      }
      .img {
        border: 1px solid var(--pure-white);
        padding: 0.4rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        width: 200px;
        height: 200px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
}

@media screen and (max-width: 1024px){
  .container{
    width: 80%;
    .title{
      font-size: 2.5rem;
    }
    .text{
      font-size: 1.1rem;
    }
    .teams{
      .team{
        .name{
          font-size: 1.5rem;
        }
        .img{
          width: 150px;
          height: 150px;

        }
        
        
      }
    }
  }
  
}

@media screen and (max-width: 768px){
  .container{
    padding: 2rem 0;
    width: 90%;
    gap: 1rem;
    .title{
      font-size: 2rem;
    }
    .text{
      font-size: 1rem;
    }
    .teams{
      flex-direction: column;
      gap: 2rem;
      margin: 2rem 0;
      .team{
        .name{
          font-size: 1.5rem;
        }
        .img{
          width: 150px;
          height: 150px;
        }
      }
    }
  }
  
}
