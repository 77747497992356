/* styles.module.css */

.container {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Adjust as needed */
}

.problemContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px; /* Adjust as needed */
    border-radius: 8px; /* Optional styling */
}

.problemContainer.reverse {
    flex-direction: row-reverse;
}

.imageContainer img {
    width: 20rem;
    border-radius: 4px; /* Optional styling */
}

.contentContainer {
    max-width: 60%; /* Adjust as needed */
    padding: 20px; /* Adjust as needed */
}

.contentContainer h2 {
    margin: 0;
    font-size: 24px; /* Adjust as needed */
    color: var(--pure-white); /* Adjust as needed */
}

.contentContainer p {
    font-size: 16px; /* Adjust as needed */
       color: var(--pure-white); /* Adjust as needed */
 /* Adjust as needed */
    line-height: 1.5;
}
