video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  opacity: 0.9;
}

.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  position: relative;
}

.hero-container > h1 {
  color: #fff;
  font-size: 150px;
  margin: 0;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  text-align: center;
  font-weight: 600;
}

.extra {
  position: absolute;
  bottom: 50px;
  color: var(--pure-white);
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.nav {
  position: absolute;
  top: 20px;
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  .left {
    font-size: 3rem;
    font-weight:bold;
    display: flex;
    align-items: center;
    color: #014421;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    letter-spacing: 2px;
    gap: 1rem;
    .logo {
      width: 4rem;
    }
  }
  .right {
    color: #fff;
    font-size: 1.5rem;
    font-weight:500;
    display: flex;
    letter-spacing: 2px;
    gap: 1.5rem;
    .item{
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover{
        color: #014421;
        scale: 1.1;
      }
    
    }
  }
}
.hamburger {
  display: none;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .hamburger {
    display: block;
  }
}


@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
  .nav .left{
    font-size: 2rem;
    .logo{
      width: 3rem;
    }
  }

  .nav .right{
    display: none;
  }
  
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }
  .nav .left{
    font-size: 1.5rem;
    .logo{
      width: 2rem;
    }
  }

}
