.container{
    display: flex;
    width: 100%;
    height: 100%;
    .left{
        flex:1;
        display: flex;
        justify-content: center;
        align-items: center;
        .img{
            width: 80%;
            border-radius: 4px;
        }
    }
    .right{
        flex:1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding: 2rem 4rem;
        .title{
            color: var(--light-green);
            font-size: 3rem;
            font-weight: 600;
        }
        .subtitle{
            color: var(--light-green);
            font-size: 1.5rem;
            font-weight: 600;
        }
        .text{
            font-size: 1.2rem;
            text-align: justify;
            
        }
    }
}

@media screen and (max-width: 1024px){
    .container{
        flex-direction: column;
        .left{
            .img{
                width: 100%;
                height: 30rem;
            }
        }
        .right{
            padding: 1rem 2rem;
            .title{
                font-size: 2rem;
            }
            .subtitle{
                font-size: 1.2rem;
            }
            .text{
                font-size: 1rem;
            }
        }
    }
}



@media screen and (max-width: 768px){
    .container{
        flex-direction: column;
        .left{
            .img{
                width: 100%;
            }
        }
        .right{
            padding: 1rem 2rem;
            .title{
                font-size: 1.5rem;
            }
            .subtitle{
                font-size: 1rem;
            }
            .text{
                font-size: 0.8rem;
            }
        }
    }
}