.container {
  width: 100%;
  background-color: var(--pure-white);
  .top{
    padding: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .contact {
        display: flex;
        flex-direction: column;
        p {
            font-size: 16px;
            color: var(--pure-black);
        }
    }
    .form{
        display: flex;
        flex-direction: column;
        input {
            width: 200px;
            height: 20px;
            margin: 10px 0;
            padding: 5px;
        }
        
        button {
            width: 100px;
            height: 30px;
            margin: 10px 0;
            padding: 5px;
            background-color: var(--pure-black);
            color: var(--pure-white);
            cursor: pointer;
            margin-left: 20px;
        }

    }
    .redirects{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        a{
            text-decoration: none;
            color: var(--light-green);
            font-size: 16px;
            font-weight: bold;
        }
    }
    
  }
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .socials {
      display: flex;
      justify-content: space-around;
      width: 150px;
      a {
        color: var(--pure-black);
        font-size: 20px;
      }
    }
  }
}


@media screen and (max-width: 768px) {
    .container {

        .top {
            flex-direction: column;
            align-items: center;
            .contact {
                margin-bottom: 20px;
            }
            .form {
                margin-bottom: 20px;
            }
            .redirects {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                a {
                    text-decoration: none;
                    color: var(--light-green);
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }
    }
    
}
